import React from 'react'
import './navbardefault.css'
import logo from '../Assets/logo.png'

const NavbarDefault = () => {
  return (<>
    <div className="navbar">
      <img src={logo} ></img>
      <a id='Home' href='#' style={{textDecoration:'none'}}>Home</a>
      <a id='Events' href='./event.html'style={{textDecoration:'none'}}>Events</a>
      <a id='Contact' href="https://wa.me/918925341659" style={{textDecoration:'none'}}>Contact</a>
      <a id='Team' href="./team.html" style={{textDecoration:'none'}}>Team</a>  
    </div>
  </>
  )
}

export default NavbarDefault;