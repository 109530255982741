import NavbarDefault from "../components/NavbarDefault";
import Logo from "../components/Logo";
import ParticlesBackground from "../components/ParticlesBackground";
import  ReactDOM  from "react-dom/client";
import TextType from "../components/TextType";

const navbar=ReactDOM.createRoot(document.getElementById("navbar"));
navbar.render(<NavbarDefault/>)

const logo=ReactDOM.createRoot(document.getElementById("logomain"));
logo.render(<Logo/>)

const texttype=ReactDOM.createRoot(document.getElementById("reacttype"));
texttype.render(<TextType/>)

const particles=ReactDOM.createRoot(document.getElementById("bodyid"));
particles.render(<ParticlesBackground/>)

const canvas = document.querySelector('canvas');
const ctx = canvas.getContext('2d');

const mouse = { x: 0, y: 0 };

let trail;
let w;
let wH;
let h;
let hH;

const distanceBetween = (p1, p2) => Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y));
const angleBetween = (p1, p2) => Math.atan2(p2.y - p1.y, p2.x - p1.x);

class Trail {
  constructor(x, y, length = 0, angle = 0, delay = 1) {    
	this.points = [];

	this.x = x;
	this.y = y;
	this.delay = delay;

	this.add(x, y, length, angle);
  }

  add(x, y, length = 0, angle = 0) {    
	this.x += (x - this.x) / this.delay;
	this.y += (y - this.y) / this.delay;

	this.points.unshift({ x: this.x, y: this.y, length, angle });  
  }

  update() {
	this.points.forEach((p) => {
	  p.x += Math.cos(p.angle) * p.length;
	  p.y += Math.sin(p.angle) * p.length;

	  p.length *= 0.98;
	});

	this.points = this.points.slice(0, 80);
  }
}

const updateStage = () => {
  w = window.innerWidth;
  h = window.innerHeight;

  wH = w >> 1;
  hH = h >> 1;

  canvas.width = w;
  canvas.height = h;
  
  mouse.x = wH;
  mouse.y = hH;
  trail = new Trail(wH, hH);
};

const onResize = (e) => {
  updateStage();
};

const clear = () => {
  ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
};

const loop = () => { 
  clear();

  const points = trail.points;
  
  let hue;
  
  points.forEach((p1) => {
	points
	  .filter(p2 => p2 !== p1 && distanceBetween(p1, p2) < 100)
	  .forEach((p2) => {
		hue = 180 * (Math.cos(p1.angle) / (Math.PI * 2));

		ctx.strokeStyle = `hsla(${hue}, 100%, 50%, 0.2)`;

		ctx.beginPath();
		ctx.moveTo(p1.x, p1.y);
		ctx.lineTo(p2.x, p2.y);
		ctx.stroke();
		ctx.closePath();
	  })
  });

  if (points.length > 0) {
	ctx.beginPath();
	ctx.strokeStyle = `hsl(${hue}, 100%, 50%)`;
	ctx.moveTo(points[0].x, points[0].y);

	for (let i = 1; i < points.length; i++) {
	  ctx.lineTo(points[i].x, points[i].y);
	}

	ctx.stroke();
	ctx.closePath();
  }

  trail.add(mouse.x, mouse.y, mouse.length, mouse.angle);
  trail.update();

  requestAnimationFrame(loop);
};


window.addEventListener('resize', onResize);
onResize();
loop();

const onPointerMove = (e) => {
  const target = (e.touches && e.touches.length) ? e.touches[0] : e;
  const { clientX: x, clientY: y } = target;

  const length = distanceBetween({ x, y }, mouse) * 0.3;
  const angle = angleBetween(mouse, { x, y });

  mouse.angle = angle;
  mouse.length = length;
  mouse.x = x;
  mouse.y = y;
};

canvas.addEventListener('mousemove', onPointerMove);
canvas.addEventListener('touchmove', onPointerMove);
