import React from 'react'
import logomain from "../Assets/logo.png"

const Logo = () => {
  return (
    <img src={logomain} ></img>
  )
}


export default Logo;